import React from 'react';
import SVG from './SVG';

const Minimize = () => (
    <SVG width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 11L3 5.99999L3.7 5.29999L8 9.59999L12.3 5.29999L13 5.99999L8 11Z" />
    </SVG>

);

export default Minimize;
