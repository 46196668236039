import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import NavItem from './NavItem'
import React, { useState } from 'react'
import { Link } from 'gatsby'

/**
 * This File was inspired by https://github.com/hasura/gatsby-gitbook-starter
 */

const calculateTreeData = (edges) => {
  return edges.reduce(
    (
      accu,
      {
        node: {
          fields: { slug, title },
        },
      }
    ) => {
      const parts = slug.split('/')
      let { items: prevItems } = accu
      for (const part of parts.slice(1, -1)) {
        let tmp = prevItems.find(({ label }) => label === part)
        if (tmp) {
          if (!tmp.items) {
            tmp.items = []
          }
        } else {
          tmp = { label: part, items: [] }
          prevItems.push(tmp)
        }
        prevItems = tmp.items
      }
      const existingItem = prevItems.find(
        ({ label }) => label === parts[parts.length - 1]
      )
      if (existingItem) {
        existingItem.url = slug
        existingItem.title = title
      } else {
        prevItems.push({
          label: parts[parts.length - 1],
          url: slug,
          items: [],
          title,
        })
      }
      return accu
    },
    { items: [] }
  )
}

const Navigation = (location) => {
  const result = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: frontmatter___rank, order: ASC }) {
        edges {
          node {
            fields {
              slug
              title
            }
          }
        }
      }
    }
  `)
  const { allMdx } = result
  const [treeData] = useState(() => calculateTreeData(allMdx.edges))

  let checkLG = treeData.items.find(
    (element) => element.label === location.location.pathname.split('/')[1]
  )
  let nav = checkLG ? { items: checkLG.items } : []
  return (
    <>
      <NavList>
        <NavItem {...nav} />
        <NavItemLink
          to={`https://jp.candyhouse.co/pages/sesame-api%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84`}
        >
          API 利用規約
        </NavItemLink>
        <NavItemLink
          style={{ display: 'block' }}
          to={`https://jp.candyhouse.co/pages/sesamesdk%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84`}
        >
          SDK 利用規約
        </NavItemLink>
      </NavList>
    </>
  )
}

const NavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const NavItemLink = styled(Link)`
  margin: 0 0.5rem 1.2rem;
  padding: 19.2px;
  width: 100%;
  cursor: pointer;
  color: ${(p) => p.theme.colors.text};
  font-weight: 600;
  text-decoration: none;
  transition: color ${(p) => p.theme.transition};
  &:hover,
  &:focus,
  &.is-active {
    color: ${(p) => p.theme.colors.primary};
  }
`

export default React.memo(Navigation)
