import React from 'react';
import SVG from './SVG';

const Add = () => (
    // <SVG viewBox="0 0 24 24">
    //   <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    //   <path d="M0 0h24v24H0z" fill="none" />
    // </SVG>
<SVG width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z"/>
</SVG>

);

export default Add;
