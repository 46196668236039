import styled from '@emotion/styled';
import {Button} from 'theme-ui'
import {navigate} from "gatsby"
import React, { useState, useEffect } from 'react'


function changeURL(from,to,theURL) {
    return theURL.replace(`/${from}`, `/${to}`);
}

const SocialIcons = () => {
    const [loc, setLocationName] = useState('')
    useEffect(() => {
        setLocationName(window.location.pathname)
    }, [])
    let nowLange =loc.split('/')[1]
        return (
        <StyledSocialIcons>
            <Button style={{backgroundColor:'transparent'}} variant={nowLange === 'ja' ? 'secondary' : 'primary'}
                    onClick={() => navigate(changeURL(nowLange,'ja',loc))}>日本語</Button>
        </StyledSocialIcons>
    );
};

const StyledSocialIcons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  opacity: 0.7;
`;


export default SocialIcons;
